// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
// import { Searchbox } from "../vue-components/searchbox";

// gsap.registerPlugin(ScrollTrigger);
//
// Searchbox.mount("#vue-searchbox");

// NAVIGATION =========================
// function mainNav(id) {
//   const mainNav = document.getElementById(id);
//
//   if (mainNav) {
//     ScrollTrigger.create({
//       trigger: ".main-menu",
//       start: "top top",
//       end: "+=99999px",
//       // markers: true,
//       toggleClass: { targets: ".main-nav", className: "scrolled" },
//     });
//   }
//
//   const logo = document.getElementById("logo");
//   if (logo) {
//     ScrollTrigger.create({
//       trigger: ".main-menu",
//       start: "-=20px top",
//       end: "+=99999px",
//       // markers: true,
//       toggleClass: { targets: ".logo", className: "scrolled" },
//     });
//   }
// }
// mainNav("main-menu");

// Open and close the mobile menu
const menuBtn = document.getElementById("menu-btn");

let closeMenu = () => {
  document.body.classList.remove("menu-open");
  clearAllBodyScrollLocks();
  menuBtn.classList.remove("btn-secondary");
  menuBtn.classList.add("btn-secondary-outline");
};
let openMenu = () => {
  document.body.classList.add("menu-open");
  disableBodyScroll(document.querySelector("#main-menu"));
  menuBtn.classList.remove("btn-secondary-outline");
  menuBtn.classList.add("btn-secondary");
};

let closeMenuAfterResize = () => {
  if (window.innerWidth > 768) {
    closeMenu();
  }
};

menuBtn.onclick = function () {
  if (document.querySelector(".menu-open")) {
    closeMenu();
  } else {
    openMenu();
  }
};

document.addEventListener("keydown", function (event) {
  if (event.key === "Escape") {
    closeMenu();
  }
});
window.addEventListener("resize", closeMenuAfterResize);
